/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./ProjectStatus.scss";
import pillarHeadline from "../../../images/pinnacle/Image/heading-pillar-2.png";
import statusImage1 from "../../../images/pinnacle/Image/1-Common-Terrace-01.webp";
import statusImage2 from "../../../images/pinnacle/Image/2-Entrance-Drop-Off.webp";
import statusImage3 from "../../../images/pinnacle/Image/3-Swimming-Pool.webp";
import PinnacleData from "./PinnacleData";
import Img from "gatsby-image";
import ImageModal from "./ImageModal";

function ProjectStatus() {
  const [activeMonth, setActiveMonth] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const months = [];
  const projectData = PinnacleData();
  const projectUpdates = projectData?.[0]?.sections?.[0]?.project_updates;
  const sortedProjectUpdates = [...projectUpdates]?.reverse();

  return (
    <div className="project-status" id="project-status">
      <div className="project-status-content">
        <h1>
          <img
            src={pillarHeadline}
            alt="pillar-headline"
            className="pillar-headline"
          />
          Project Status
        </h1>
        <div className="timeline-grid">
          {sortedProjectUpdates?.map((month, index) => (
            <button
              key={index}
              className={`month-button ${
                index === activeMonth ? "active" : ""
              }`}
              onClick={() => setActiveMonth(index)}
            >
              {month.title}
            </button>
          ))}
        </div>
        <div className="status-cards">
          {sortedProjectUpdates?.[activeMonth]?.images?.map((update, index) => (
            <div
              className="status-card"
              key={index}
              onClick={() => {
                setSelectedImage(index + 1);
                setIsModalOpen(true);
              }}
            >
              <Img fluid={update.childImageSharp.fluid} alt="status-image" />
            </div>
          ))}
        </div>
      </div>

      {isModalOpen ? (
        <ImageModal
          imagesArray={sortedProjectUpdates?.[activeMonth]?.images}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          showModal={isModalOpen}
          setShowModal={setIsModalOpen}
        />
      ) : null}
    </div>
  );
}

export default ProjectStatus;
